import React from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // load on demand
import './table.css';
dayjs.locale( 'es' );

function descendingComparator ( a, b, orderBy ) {
    if ( b[ orderBy ] < a[ orderBy ] )
    {
        return -1;
    }
    if ( b[ orderBy ] > a[ orderBy ] )
    {
        return 1;
    }
    return 0;
}

function getComparator ( order, orderBy ) {
    return order === 'desc'
        ? ( a, b ) => descendingComparator( a, b, orderBy )
        : ( a, b ) => -descendingComparator( a, b, orderBy );
}

function stableSort ( array, comparator ) {
    const stabilizedThis = array.map( ( el, index ) => [ el, index ] );
    stabilizedThis.sort( ( a, b ) => {
        const order = comparator( a[ 0 ], b[ 0 ] );
        if ( order !== 0 ) return order;
        return a[ 1 ] - b[ 1 ];
    } );
    return stabilizedThis.map( ( el ) => el[ 0 ] );
}

const headCells = [
    { id: 'portal', numeric: true, disablePadding: false, label: 'Portal' },
    { id: 'createdAt', numeric: false, disablePadding: false, label: 'Fecha/Hora' },
    { id: 'vin', numeric: true, disablePadding: false, label: 'NIV' },
    { id: 'marca', numeric: false, disablePadding: false, label: 'Marca' },
    { id: 'modelo', numeric: false, disablePadding: false, label: 'Año' },
    { id: 'placa', numeric: false, disablePadding: false, label: 'Placa' },
    { id: 'color', numeric: false, disablePadding: false, label: 'Color' },
    { id: 'siniestro', numeric: false, disablePadding: false, label: 'Siniestro' },
    { id: 'fecha_robo', numeric: false, disablePadding: false, label: 'Fecha de Robo' },
];

function EnhancedTableHead ( props ) {
    const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = ( property ) => ( event ) => {
        onRequestSort( event, property );
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={ numSelected > 0 && numSelected < rowCount }
                        checked={ rowCount > 0 && numSelected === rowCount }
                        inputProps={ { 'aria-label': 'select all desserts' } }
                    />
                </TableCell>
                { headCells.map( ( headCell ) => (
                    <TableCell
                        key={ headCell.id }
                        align={ headCell.numeric ? 'center' : 'right' }
                        padding={ headCell.disablePadding ? 'none' : 'normal' }
                        sortDirection={ orderBy === headCell.id ? order : false }
                    >
                        <TableSortLabel
                            active={ orderBy === headCell.id }
                            direction={ orderBy === headCell.id ? order : 'asc' }
                            onClick={ createSortHandler( headCell.id ) }
                        >
                            { headCell.label }
                            { orderBy === headCell.id ? (
                                <span className={ classes.visuallyHidden }>
                                    { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                </span>
                            ) : null }
                        </TableSortLabel>
                    </TableCell>
                ) ) }
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf( [ 'asc', 'desc' ] ).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles( ( theme ) => ( {
    root: {
        paddingLeft: theme.spacing( 2 ),
        paddingRight: theme.spacing( 1 ),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten( theme.palette.secondary.light, 0.85 ),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
} ) );

const EnhancedTableToolbar = ( props ) => {
    const classes = useToolbarStyles();
    const { numSelected, tableName } = props;


    return (
        <Toolbar
            className={ clsx( classes.root, {
                [ classes.highlight ]: numSelected > 0,
            } ) }
        >
            { numSelected > 0 ? (
                <Typography className={ classes.title } color="inherit" variant="subtitle1" component="div">
                    { numSelected } seleccionados
                </Typography>
            ) : (
                <Typography className={ classes.title } color='error' variant="h6" id="tableTitle" component="div">
                    { tableName }
                </Typography>
            ) }

            { numSelected > 0 ? (
                <Tooltip title="Borrar">
                    <IconButton aria-label="Borrar">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            ) }
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        width: '100%',
        marginTop: '10px',
        marginLeft: '20px',
        marginRight: '20px',
        minWidth: '200px',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing( 2 ),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    headerConReporte: {
        borderColor: "#ad301b",
    }
} ) );

export default function EnhancedTable ( props ) {
    const { tableName, data } = props;
    const classes = useStyles();
    const [ order, setOrder ] = React.useState( 'asc' );
    const [ orderBy, setOrderBy ] = React.useState( 'calories' );
    const [ selected, setSelected ] = React.useState( [] );
    const [ page, setPage ] = React.useState( 0 );
    const [ dense, setDense ] = React.useState( true );
    const [ rowsPerPage, setRowsPerPage ] = React.useState( 5 );
    let history = useHistory();

    //console.log( "DATOS EN PROPS", data );
    const rows = [ ...data ];
    const handleRequestSort = ( event, property ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder( isAsc ? 'desc' : 'asc' );
        setOrderBy( property );
    };

    const handleClick = ( event, name ) => {
        const selectedIndex = selected.indexOf( name );
        let newSelected = [];
        console.log( "Seleccionada ", name );
        history.push( "/detalle/" + name );
        /* if ( selectedIndex === -1 )
        {
            newSelected = newSelected.concat( selected, name );
        } else if ( selectedIndex === 0 )
        {
            newSelected = newSelected.concat( selected.slice( 1 ) );
        } else if ( selectedIndex === selected.length - 1 )
        {
            newSelected = newSelected.concat( selected.slice( 0, -1 ) );
        } else if ( selectedIndex > 0 )
        {
            newSelected = newSelected.concat(
                selected.slice( 0, selectedIndex ),
                selected.slice( selectedIndex + 1 ),
            );
        }

      setSelected( newSelected ); */
    };

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage );
    };

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) );
        setPage( 0 );
    };

    /* const handleChangeDense = ( event ) => {
        setDense( event.target.checked );
    };
 */
    const isSelected = ( name ) => selected.indexOf( name ) !== -1;

    const emptyRows = rowsPerPage - Math.min( rowsPerPage, rows.length - page * rowsPerPage );

    return (
        <div className={ classes.root }>
            <Paper className={ classes.paper }>
                <EnhancedTableToolbar numSelected={ selected.length } tableName={ tableName } />
                <TableContainer>
                    <Table
                        className={ classes.table }
                        aria-labelledby="tableTitle"
                        size={ dense ? 'small' : 'medium' }
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={ classes }
                            numSelected={ selected.length }
                            order={ order }
                            orderBy={ orderBy }
                            onRequestSort={ handleRequestSort }
                            rowCount={ rows.length }
                            className={ classes.headerConReporte }


                        />
                        <TableBody>
                            { stableSort( rows, getComparator( order, orderBy ) )
                                .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                .map( ( row, index ) => {
                                    const isItemSelected = isSelected( row.name );
                                    const labelId = `enhanced-table-checkbox-${ index }`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={ ( event ) => handleClick( event, row.id ) }
                                            role="checkbox"
                                            aria-checked={ isItemSelected }
                                            tabIndex={ -1 }
                                            key={ row.id }
                                            selected={ isItemSelected }
                                            className="backgroundAnimatedConReporte"
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={ isItemSelected }
                                                    inputProps={ { 'aria-labelledby': labelId } }
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={ labelId } scope="row" padding="none">
                                                { row.portal }
                                            </TableCell>
                                            <TableCell align="right">{ dayjs( row.createdAt ).format( 'DD-MMM-YY HH:mm' ) }</TableCell>
                                            <TableCell align="right">{ row.vin }</TableCell>
                                            <TableCell align="right">{ row.marca }</TableCell>
                                            <TableCell align="right">{ row.modelo }</TableCell>
                                            <TableCell align="right">{ row.placa }</TableCell>
                                            <TableCell align="right">{ row.color }</TableCell>
                                            <TableCell align="right">{ row.siniestro }</TableCell>
                                            <TableCell align="right">{ dayjs( row.fecha_robo ).format( 'DD-MMM-YYYY HH:mm' ) }</TableCell>
                                        </TableRow>
                                    );
                                } ) }
                            { emptyRows > 0 && (
                                <TableRow style={ { height: ( dense ? 33 : 53 ) * emptyRows } }>
                                    <TableCell colSpan={ 6 } />
                                </TableRow>
                            ) }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={ [ 5, 10, 25 ] }
                    component="div"
                    count={ rows.length }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onPageChange={ handleChangePage }
                    onRowsPerPageChange={ handleChangeRowsPerPage }
                    labelRowsPerPage="Filas por página"
                />
            </Paper>
            {/*  <FormControlLabel
                control={ <Switch checked={ dense } onChange={ handleChangeDense } /> }
                label="Dense padding"
            /> */}
        </div>
    );
}