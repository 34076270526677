import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import http from '../../servicios/httpService';
import MaterialTable, { MTableEditField } from "material-table";
import { toast } from "react-toastify";
import './base.css';
import _ from 'lodash';
import { DataUsageTwoTone } from '@material-ui/icons';



function Usuarios ( props ) {

    const [ datos, setDatos ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( true );
    let history = useHistory();

    useEffect( () => {
        const fetchData = async () => {
            try
            {
                const response = await http.get( process.env.REACT_APP_ENDPOINT + "api/user", {

                    //withCredentials: true,
                } );
                //console.log( "Respuesta Usuarios: ", response.data );
                setDatos( response.data );
                setIsLoading( false );



            } catch ( error )
            {
                if ( error.response.status === 401 )
                {
                    //console.log( "Error en catch: ", error.response.status );
                    toast.error( "Su sesión caduco, inicie de nuevo" );
                    history.push( "/login" );
                }

            }

        };

        fetchData();
    }, [ history ] );

    let rows = [];
    if ( datos )
    {
        rows =
            datos.map(
                dato => {
                    return ( {
                        "Nombre": dato.nombre,
                        "Email": dato.email,
                        "Tel": dato.tel,
                        "admin": dato.admin,
                        "Contraseña": "********"
                    } );
                }
            );
        //console.log( "DatosTabla", rows );
    }

    return (
        <div className="base tabla">
            <MaterialTable
                title="Portales"
                isLoading={ isLoading }

                localization={ {
                    pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        labelRowsPerPage: "Filas por página:",
                        firstTooltip: "Primera página",
                        previousTooltip: "Pagina anterior",
                        nextTooltip: "Siguiente página",
                        lastTooltip: "Última página",

                    },
                    header: {
                        actions: "Acciónes",
                    },
                    body: {
                        emptyDataSourceMessage:
                            "No hay registros que mostrar",
                        editRow: {
                            deleteText:
                                "¿Estás seguro de eliminar esta fila?",
                            cancelTooltip: "Cancelar",
                            saveTooltip: "Guardar",
                        },
                    },
                } }
                columns={ [
                    { title: 'Nombre', field: 'Nombre' },
                    { title: 'Email', field: 'Email' },
                    { title: 'Tel', field: 'Tel' },
                    { title: 'Admin', field: 'admin' },
                    { title: 'Contraseña', field: 'Contraseña' }

                ] }
                data={ rows }
                options={ {
                    search: true,
                    sorting: true,
                    exportAllData: true,
                    exportButton: true,
                    headerStyle: {
                        backgroundColor: "#2E3B55",
                        color: "#FFF",
                        fontSize: "large",
                        fontWeight: "bold",

                    },
                } }
                components={ {
                    EditField: ( props ) => {
                        const regex = new RegExp( "^[^<>=:]+$" );

                        if (
                            props.rowData.hasOwnProperty( "destino" ) &&
                            props.rowData.destino.length === 0
                        )
                        {
                            return (
                                <MTableEditField
                                    { ...props }
                                    error
                                    label="campo requerido"
                                />
                            );
                        }

                        if ( !regex.test( props.rowData.destino ) )
                        {
                            return (
                                <MTableEditField
                                    { ...props }
                                    error
                                    label="campo no valido"
                                />
                            );
                        }

                        return <MTableEditField { ...props } />;
                    },
                } }
                editable={ {
                    onRowAdd: ( newData ) =>
                        new Promise( ( resolve, reject ) => {
                            //console.log( "OLD", datos );
                            //console.log( "NewData", newData );
                            const regex = new RegExp( "^[^<>=:]+$" );
                            if (
                                !newData.destino ||
                                !regex.test( newData.destino )
                            )
                            {
                                reject();
                            }
                            const aGrabar = {
                                name: newData.Nombre,
                                email: newData.Email,
                                password: newData.Contraseña,
                                tel: newData.Tel,
                                admin: newData.admin ? true : false
                            };
                            http
                                .post( process.env.REACT_APP_ENDPOINT + "api/user/", aGrabar )
                                .then( resp => {
                                    //console.log( "Respuesta http", _.omit( resp.data, [ "createdAt", "updatedAt" ] ) );
                                    setDatos( [ ...datos, _.omit( resp.data, [ "createdAt", "updatedAt" ] ) ] );
                                    resolve();

                                } )
                                .catch( ( error ) => {
                                    if (
                                        error.response &&
                                        error.response.data
                                    )
                                    {

                                        //console.log( error.response.data );

                                        reject();
                                    }
                                } );
                        } ),
                    onRowUpdate: ( newData, oldData ) =>
                        new Promise( ( resolve, reject ) => {
                            const regex = new RegExp( "^[^<>=:]+$" );
                            if (
                                !newData.destino ||
                                !regex.test( newData.destino )
                            )
                            {
                                reject();
                            }
                            http
                                .put( process.env.REACT_APP_ENDPOINT + "api/user/", newData )
                                .then( () => {
                                    /* const index = data.indexOf( oldData );
                                    data[ index ] = newData;
                                    this.setState( { data }, () =>
                                        resolve()
                                    ); */
                                } )
                                .catch( ( error ) => {
                                    if (
                                        error.response &&
                                        error.response.data
                                    )
                                    {
                                        console.log( error.response.data );
                                        reject();
                                    }
                                } );
                        } ),
                    onRowDelete: ( oldData ) =>
                        new Promise( ( resolve ) => {
                            //console.log( "OLD_DATA", oldData );
                            http
                                .delete( process.env.REACT_APP_ENDPOINT + `api/user/${ oldData.Email }` )
                                .then( () => {
                                    //console.log( datos );
                                    const nuevos = datos.filter( dato => { if ( dato.email !== oldData.Email ) { return dato; } } );
                                    //console.log( nuevos );
                                    setDatos( nuevos );
                                    resolve();
                                } );
                        } ),
                } }
            />




        </div>
    );



};

export default Usuarios;