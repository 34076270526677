import React, { useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Notify from "@material-ui/icons/Notifications";
import Grid from "@material-ui/core/Grid";
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TodayIcon from '@material-ui/icons/Today';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import QueueIcon from '@material-ui/icons/Queue';
import Toolbar from '@material-ui/core/Toolbar';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import './menu.css';
import decodeToken from '../../servicios/decode_jwt';

const drawerWidth = 240;

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        display: 'flex',
    },
    drawer: {
        [ theme.breakpoints.up( 'sm' ) ]: {
            width: drawerWidth,
            flexShrink: 0
        },
    },
    appBar: {
        [ theme.breakpoints.up( 'sm' ) ]: {
            width: `calc(100% - ${ drawerWidth }px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing( 2 ),
        [ theme.breakpoints.up( 'sm' ) ]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing( 3 ),
    },
} ) );



function Menu ( props ) {

    useEffect( () => {
        const usuario = decodeToken();
        //console.log( "Token en menu:", usuario );
        setUsuario( usuario );

    }, [] );


    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [ mobileOpen, setMobileOpen ] = React.useState( false );
    const [ usuario, setUsuario ] = useState( {} );

    const handleDrawerToggle = () => {
        setMobileOpen( !mobileOpen );
    };
    //console.log( "Token en menu:", decodeToken() );

    const drawer = (
        <div>
            <div className={ classes.toolbar } />
            <Divider />
            <List>
                <Link to="/tablero" className="linkMenu">
                    <ListItem button key={ 1 }>
                        <ListItemIcon>< DashboardIcon /> </ListItemIcon>
                        <ListItemText primary={ "Tablero" } />
                    </ListItem>
                </Link>
                <Divider />
                <ListItem button key={ 2 }>
                    <ListItemText primary={ "Reportes" } />
                </ListItem>
                <Link to="/retornables" className="linkMenu">
                    <ListItem button key={ 3 }>
                        <ListItemIcon>< ShoppingBasketIcon /> </ListItemIcon>
                        <ListItemText primary={ "Por NIV" } />
                    </ListItem>
                </Link>
                <Link to="/ubicacion" className="linkMenu">
                    <ListItem button key={ 4 }>
                        <ListItemIcon>< MyLocationIcon /> </ListItemIcon>
                        <ListItemText primary={ "Ubicación" } />
                    </ListItem>
                </Link>
                <Link to="/fecha" className="linkMenu">
                    <ListItem button key={ 5 }>
                        <ListItemIcon>< TodayIcon /> </ListItemIcon>
                        <ListItemText primary={ "Fecha" } />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <ListItem button key={ 6 }>
                <ListItemText primary={ "Catálogos" } />
            </ListItem>
            <Link to="/administracion_portales" className="linkMenu">
                <ListItem button key={ 7 }>
                    <ListItemIcon>< EditLocationIcon /> </ListItemIcon>
                    <ListItemText primary={ "Portales" } />
                </ListItem>
            </Link>
            <Link to="/administracion_usuarios" className="linkMenu">
                <ListItem button key={ 8 }>
                    <ListItemIcon>< PeopleIcon /> </ListItemIcon>
                    <ListItemText primary={ "Usuarios" } />
                </ListItem>
            </Link>
            <Divider />

            <ListItem button key={ 9 }>
                <ListItemText primary={ "Configuración" } />
            </ListItem>
            <Link to="/configurar" className="linkMenu">
                <ListItem button key={ 10 }>
                    <ListItemIcon>< SettingsIcon /> </ListItemIcon>
                    <ListItemText primary={ "Configurar" } />
                </ListItem>
            </Link>

            <Link to="/logout" className="linkMenu">
                <ListItem button key={ 11 }>
                    <ListItemIcon>< ExitToAppIcon /> </ListItemIcon>
                    <ListItemText primary={ "Salir" } />
                </ListItem>
            </Link>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={ classes.root }>
            <CssBaseline />
            <AppBar position="fixed" className={ classes.appBar } style={ { background: '#2E3B55' } }>

                <Toolbar>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={ handleDrawerToggle }
                        className={ classes.menuButton }
                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="h6" color="initial" display="inline" noWrap>
                                Lecturas Repuve
                            </Typography>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Typography variant="subtitle2" >{ usuario?.email }</Typography>
                            <Typography variant="subtitle2" >{ usuario?.seguridad }</Typography>
                        </Grid>

                        <Grid item>
                            <IconButton color="inherit">
                                <Notify />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton>
                                <AccountCircleIcon fontSize="large" htmlColor="#079987" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <nav className={ classes.drawer } aria-label="mailbox folders">

                {/* The implementation can be swapped with js to avoid SEO duplication of links. */ }
                <Hidden smUp implementation="css">

                    <Drawer
                        container={ container }
                        variant="temporary"
                        anchor={ theme.direction === 'rtl' ? 'right' : 'left' }
                        open={ mobileOpen }
                        onClose={ handleDrawerToggle }
                        classes={ {
                            paper: classes.drawerPaper,
                        } }
                        ModalProps={ {
                            keepMounted: true, // Better open performance on mobile.
                        } }
                    >
                        { drawer }

                    </Drawer>


                </Hidden>

                <Hidden xsDown implementation="css">


                    <Drawer
                        classes={ {
                            paper: classes.drawerPaper,
                        } }
                        variant="permanent"
                        open
                    >
                        { drawer }
                        <img src="/movillogo200.png" className="permanente" alt="logo" />
                    </Drawer>
                </Hidden>
            </nav>



        </div>
    );
}

export default Menu;


