import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/login/login";
import Logout from "./components/login/logout";
import Main from "./views/main/main";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertMUITemplate from "react-alert-template-mui";
import UserContext from "./context/userContext";
import decode from "./servicios/decode_jwt";
import { setHeaders } from "./servicios/httpService";
//import ReactGA from "react-ga";
//ReactGA.initialize("G-DVNVGNQFXW");

setHeaders();
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 10000,
  offset: "30px",
  type: "info",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
function App() {
  const [usuario] = useState({
    email: "",
    empresa: "",
    seguridad: "",
  });

  /*   useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log("Corriendo GA");
  }, []); */

  return (
    <div className="App">
      <ToastContainer />
      <AlertProvider template={AlertMUITemplate} {...options}>
        <UserContext.Provider value={usuario}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route
              path="/"
              render={(props) => {
                if (!decode()) return <Redirect to="/Login" />;
                return <Main {...props} />;
              }}
            />
          </Switch>
        </UserContext.Provider>
      </AlertProvider>
    </div>
  );
}

export default App;
