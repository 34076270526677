import http from "./httpService";

const endPointLogin = process.env.REACT_APP_ENDPOINT + "api/auth";

export function login(email, password) {
  return http.post(
    endPointLogin,
    { email, password }
    //{ withCredentials: true }
  );
}
