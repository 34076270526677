import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import http from '../../servicios/httpService';
import { toast } from "react-toastify";
import Mapa from '../../components/mapa/Mapa';
import MediaCard from '../../components/cards/mediacard';


const Detalle = ( props ) => {
    const id = props.location.pathname.substr( props.location.pathname.lastIndexOf( "/" ) + 1 );
    console.log( "ID con Reporte ", id );
    let history = useHistory();





    const [ detalle, setDetalle ] = useState( null );

    useEffect( () => {

        const fetchConReporte = async () => {

            try
            {
                const response = await http.get( process.env.REACT_APP_ENDPOINT + "api/con_reporte/" + id, {
                    //withCredentials: true,
                } );
                console.log( "Respuesta detalle: ", response.data );
                setDetalle( response.data );

            } catch ( error )
            {
                if ( error.response.status === 401 )
                {
                    //console.log( "Error en catch: ", error.response.status );
                    toast.error( "Su sesión caduco, inicie de nuevo" );
                    history.push( "/login" );
                }

            }


        };

        fetchConReporte();


    }, [] );













    if ( detalle )
    {
        return ( <div className="cuerpo_detalle">
            <Mapa info={ detalle } />
            <MediaCard info={ detalle } />
        </div> );
    } else
    {
        console.log( "Entro sin datos" );
        return (

            <div></div>
        );
    }


};

export default Detalle;