import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Button, Card, Typography } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import './Mapa.css';


/* 
documentacion 
https://react-google-maps-api-docs.netlify.app/#section-introduction

*/


/* const heatMapData = {
    positions: [
        { lat: 20.648672, lng: -87.063781 },
        { lat: 20.647672, lng: -87.069781 },
        { lat: 20.642913, lng: -87.071819 },
        { lat: 20.641923, lng: -87.071829 },
        { lat: 20.641523, lng: -87.071829 },
        { lat: 20.641423, lng: -87.071829 },
        { lat: 20.641823, lng: -87.071829 },
        { lat: 20.642923, lng: -87.071829 },
    ],
    options: {
        radius: 100,
        opacity: 0.4,

    }
};
 */

function geometricMean (arr) {
const n = arr.length;
    console.log( "Entro para array de Geometic Mean", arr );
    let productLat = 1;
    let productLng = 1;
    let gmLng;
    for ( let i = 0; i < n; i++ )
  {      productLat = productLat * arr[ i ].lat;
    productLng = productLng * arr[ i ].lng;}

    let gmLat = Math.pow( Math.abs( productLat ), 1 / n );
    let lngTemp = Math.pow( Math.abs( productLng ), 1 / n );
    console.log( "lngTemp", lngTemp );
    if ( lngTemp>0 )
    {
        gmLng = -lngTemp;
    } else
    {
        gmLng = lngTemp;
    }
   
    let result = { lat: gmLat, lng: gmLng }
    console.log( "Resultado de Mean", result );
    return result;
}

//Calculo de distancia entre dos puntos geograficos

const distancia = ( punto1, punto2 ) => {
    //console.log( "Puntos que entraron a funcion", punto1, punto2 );

    const { lat1, lon1 } = punto1;
    const { lat2, lon2 } = punto2;
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = ( lat2 - lat1 ) * Math.PI / 180;
    const Δλ = ( lon2 - lon1 ) * Math.PI / 180;

    const a = Math.sin( Δφ / 2 ) * Math.sin( Δφ / 2 ) +
        Math.cos( φ1 ) * Math.cos( φ2 ) *
        Math.sin( Δλ / 2 ) * Math.sin( Δλ / 2 );
    const c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );

    const distance = R * c; // in metres

    return distance;
}

const AnyReactComponent = ( { text } ) => <div style={ { color: "red" } }><VerifiedUserIcon /> <h1>{ text }</h1></div>;
const center = {
    lat: 20.642903,
    lng: -87.071809
};
const defaultProps = {
    center: {
        lat: 20.642903,
        lng: -87.071809
    },
    zoom: 13
};


/*const marcador = {
    lat: 20,
    lng: -87
};

const mapContainerStyle = {
    width: '350px',
    height: '250px',
    margin: "10px"
};*/

const onClick = ( e ) => {

    console.info( 'Click: ', e );
};


const useStyles = makeStyles( {
    root: {

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        align: "center"

    },
    pos: {
        marginBottom: 12,
    },
} );
const Mapa = ( { portales } ) => {

    let posicion = {};
    console.log( "Datos", portales );

    const positionsHeatMap = portales.map( dato => { return {lat: dato.lat, lng: dato.long} } );
    console.log( "HEAT", positionsHeatMap );
    //Cálculo de media geometrica para definir el centro del mapa  formula:  (x1*x2*x3*xn)^(1/n)

  let mean = geometricMean( positionsHeatMap );
    //console.log( "Mean ", mean );
    let distancias = [];
   let distanciaMaxima = 0;   let zoom = 0;
    if ( Object.is( mean.lat, NaN) )
    {
        //console.log( "Es NaN" );
        mean = { lat: 0, lng: 0 };
    } else
    {
        distancias = [...positionsHeatMap.map(dato=>distancia(
            { lat1: mean.lat, lon1: mean.lng },
            { lat2: dato.lat, lon2: dato.lng }
        ) ) ]
        distanciaMaxima = Math.max( ...distancias )/1000;
        if ( distanciaMaxima < 2 )
    {
        zoom = 14;
    } else if ( distanciaMaxima < 7 ){
        zoom = 13;
    } else
    {
        zoom = 12;
    }
    }

    console.log( "Distancias", distancias , "metros");

    console.log( "Distancia Maxima", distanciaMaxima,"Km"  );
    //console.log( "Zoom", zoom );
 
    


    const heatMapDataFormProps = {
        positions: positionsHeatMap,
        options: {
            radius: 50,
            opacity: 0.4,

        }
    };


    const classes = useStyles();
    return (
        <Card className="papelmapa">
            <div style={ { height: '40vh', width: '100%' } }>
                { console.log(`Datos que entran a mapa, Mean: ${JSON.stringify(mean) },    Zoom: ${zoom}",` ) }
                {mean.lat>0 ? <GoogleMapReact
                    bootstrapURLKeys={ { key: process.env.REACT_APP_MAPS } }
                    defaultCenter={ defaultProps.center }
                    defaultZoom={ defaultProps.zoom }
                    heatmapLibrary={ true }
                    heatmap={ heatMapDataFormProps }
                    center={ mean }
                    zoom={zoom}
                    onClick={ ( e ) => console.log( "click", e ) }
                    options={ {
                        mapTypeControl: true, streetViewControl: true, styles: [ {
                            featureType: "poi.business",
                            elementType: "labels",
                            stylers: [ {
                                visibility: "off"
                            } ]
                        } ],
                        minZoom:5
                    } }

                >
                    <AnyReactComponent
                        lat={ 20.672987 }
                        lng={ -87.048999 }
                        text="Portal Playa Magna"


                    /><AnyReactComponent
                        lat={ 20.610842 }
                        lng={ -87.103724 }
                        text="Portal Playa Car"


                    /><AnyReactComponent
                        lat={ 20.685825 }
                        lng={ -87.123391 }
                        text="Portal El Tintal"


                    />
                </GoogleMapReact> : <div>{ /* console.log("No se presento mapa") */}</div>}
            </div></Card>
    );
};

export default Mapa;