import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import jwt_decode from "jwt-decode";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { login } from '../../servicios/authservices';
import { setHeaders } from "../../servicios/httpService";
import './login.css';




function Copyright () {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            { 'Derechos Reservados ® ' }
            <Link color="inherit" to="/">
                Movil Track, SA de CV
            </Link>{ ' ' }
            { new Date().getFullYear() }
            { '.' }
        </Typography>
    );
}

const imagenAleatoria = () => {   //genera un numero entre 0 y 2 para cambiar la imagen de background 
    let numero = Math.floor( ( Math.random() * 2 ) );
    //console.log( numero );
    return numero;
};


const useStyles = makeStyles( ( theme ) => ( {
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(' + imagenAleatoria() + '.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[ 50 ] : theme.palette.grey[ 900 ],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing( 8, 4 ),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing( 1 ),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing( 1 ),
    },
    submit: {
        margin: theme.spacing( 3, 0, 2 ),
    },
} ) );

export default function Login () {

    const [ cuenta, setCuenta ] = useState( { "email": "", "password": "" } );
    const classes = useStyles();
    let history = useHistory();


    const handleSignIn = async ( e ) => {
        e.preventDefault();

        //console.log( "Login", cuenta );

        const respuesta = await login( cuenta.email, cuenta.password );

        //console.log( "Respuesta", respuesta );
        if ( respuesta.status === 200 )
        {
            //console.log( jwt_decode( respuesta.headers[ 'x-auth-repuve' ] ) );
            localStorage.setItem( "token", respuesta.headers[ 'x-auth-repuve' ] );
            setHeaders();
            history.push( "/" );
        }


    };

    const handleChange = ( e ) => {
        const datos = { ...cuenta };
        datos[ e.currentTarget.name ] = e.currentTarget.value;
        setCuenta( datos );
    };



    return (
        <Grid container component="main" className={ classes.root }>
            <CssBaseline />
            <Grid item xs={ false } sm={ 4 } md={ 7 } className={ classes.image } />
            <Grid item xs={ 12 } sm={ 8 } md={ 5 } component={ Paper } elevation={ 6 } square>
                <div className={ classes.paper }>
                    <Avatar className={ classes.avatar }>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Entrar
                    </Typography>
                    <form className={ classes.form } noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo Electrónico"
                            name="email"

                            autoFocus
                            value={ cuenta.email }
                            onChange={ handleChange }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={ cuenta.password }
                            onChange={ handleChange }
                        />
                        <FormControlLabel
                            control={ <Checkbox value="remember" color="primary" /> }
                            label="Recuerdame"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={ classes.submit }
                            onClick={ handleSignIn }
                        >
                            Entrar
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    ¿Olvido su contraseña?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    { "¿No tiene cuenta? Registrese aqui" }
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={ 5 }>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}