import React from 'react';
import { toast } from 'react-toastify';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import './mediacard.css';
import { estatus_localizado } from "../../estatus_localizado";
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // load on demand
dayjs.locale( 'es' );


function pruebaToast () {
    toast.error( "prueba", { hideProgressBar: false } );

}



export default function MediaCard ( { info } ) {

    console.log( estatus_localizado );
    const portal = "Portal";
    const cantidad = 3;
    let imagen = null;
    if ( !imagen )
        imagen = "/alert1.png";
    return (
        <Card className="root" onClick={ pruebaToast }>
            <CardActionArea>
                <CardMedia
                    className="media"
                    component="img"
                    image={ imagen }
                    title={ portal }
                />
                <CardContent>

                    <Typography variant="h2" color="textSecondary" align="center" component="p">
                        { info.vin }<br />Placa: <b>{ info.placa }</b>
                    </Typography>
                    <Typography gutterBottom variant="h5" align="left" >
                        Marca:   { info.marca }<br />
                        Modelo:   { info.modelo }<br />
                        Tipo:   { info.tipo }<br />
                        Color:   { info.color }<br />
                        Tipo de Transporte:   { info.tipo_transporte }<br />
                        Estatus de Localizado:   { info.estatus_localizado ? estatus_localizado[ info.estatus_localizado - 1 ] : "Sin Información" }<br />
                        Siniestro:   { info.siniestro }<br />
                        Fecha de Robo:   { dayjs( info.fecha_robo ).format( 'DD-MMM-YY HH:mm' ) }<br />
                        Aseguradora:   { info.aseguradora }<br />
                    </Typography>
                </CardContent>
            </CardActionArea>

        </Card>
    );
}
