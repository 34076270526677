import axios from "axios";
//import logger from "./logService";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  //console.log("Entro al interceptor de Axios Error: ", error);
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log(error);
    toast.error("Error, contacte a soporte");
  }

  return Promise.reject(error);
});

//axios.defaults.headers.common["x-auth-repuve"] = localStorage.getItem("token");

export const setHeaders = () => {
  //console.log("Entro a settear headers");
  axios.defaults.headers.common["x-auth-repuve"] =
    localStorage.getItem("token");
};
//console.log("Token en axios", localStorage.getItem("token"));
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
