import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import http from '../../servicios/httpService';
import MaterialTable, { MTableEditField } from "material-table";
import { toast } from "react-toastify";
import './base.css';



function Portales ( props ) {

    const [ datos, setDatos ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( true );
    let history = useHistory();

    useEffect( () => {
        const fetchData = async () => {
            try
            {
                const response = await http.get( process.env.REACT_APP_ENDPOINT + "api/sin_reporte/portales", {

                    //withCredentials: true,
                } );
                console.log( "Respuesta Portales: ", response.data );
                console.log( response.data );
                setDatos( response.data );
                setIsLoading( false );



            } catch ( error )
            {
                if ( error.response.status === 401 )
                {
                    //console.log( "Error en catch: ", error.response.status );
                    toast.error( "Su sesión caduco, inicie de nuevo" );
                    history.push( "/login" );
                }

            }

        };

        fetchData();
    }, [ history ] );

    let rows = [];
    if ( datos )
    {
        rows =
            datos.map(
                dato => {
                    return ( { "id": dato._id, "Portal": dato.portal, "Ubicacion": dato.ubicacion } );
                }
            );
        //console.log( "DatosTabla", rows );
    }

    return (
        <div className="base tabla">
            <MaterialTable
                title="Portales"
                isLoading={ isLoading }
                actions={ [
                    {
                        icon: 'save',
                        tooltip: 'Save User',
                        onClick: ( event, rowData ) => {
                            // Do save operation
                        }
                    }
                ] }
                localization={ {
                    pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        labelRowsPerPage: "Filas por página:",
                        firstTooltip: "Primera página",
                        previousTooltip: "Pagina anterior",
                        nextTooltip: "Siguiente página",
                        lastTooltip: "Última página",
                    },
                    header: {
                        actions: "Acciónes",
                    },


                    body: {
                        emptyDataSourceMessage:
                            "No hay registros que mostrar",
                        editRow: {
                            deleteText:
                                "¿Estás seguro de eliminar esta fila?",
                            cancelTooltip: "Cancelar",
                            saveTooltip: "Guardar",
                        },
                    },
                } }
                columns={ [
                    { title: 'Portal', field: 'Portal' },
                    { title: 'Ubicación', field: 'Ubicacion' }
                ] }
                data={ rows }
                options={ {
                    search: true,
                    sorting: true,
                    exportAllData: true,
                    exportButton: true,
                    headerStyle: {
                        backgroundColor: "#2E3B55",
                        color: "#FFF",
                        fontSize: "large",
                        fontWeight: "bold",

                    },
                } }
                components={ {
                    EditField: ( props ) => {
                        const regex = new RegExp( "^[^<>=:]+$" );

                        if (
                            props.rowData.hasOwnProperty( "destino" ) &&
                            props.rowData.destino.length === 0
                        )
                        {
                            return (
                                <MTableEditField
                                    { ...props }
                                    error
                                    label="campo requerido"
                                />
                            );
                        }

                        if ( !regex.test( props.rowData.destino ) )
                        {
                            return (
                                <MTableEditField
                                    { ...props }
                                    error
                                    label="campo no valido"
                                />
                            );
                        }

                        return <MTableEditField { ...props } />;
                    },
                } }
                editable={ {
                    onRowAdd: ( newData ) =>
                        new Promise( ( resolve, reject ) => {
                            const regex = new RegExp( "^[^<>=:]+$" );
                            if (
                                !newData.destino ||
                                !regex.test( newData.destino )
                            )
                            {
                                reject();
                            }
                            http
                                .post( "destinos", newData )
                                .then( () => {
                                    resolve();
                                    //this.getData();
                                } )
                                .catch( ( error ) => {
                                    if (
                                        error.response &&
                                        error.response.data
                                    )
                                    {
                                        console.log( error.response.data );
                                        reject();
                                    }
                                } );
                        } ),
                    onRowUpdate: ( newData, oldData ) =>
                        new Promise( ( resolve, reject ) => {
                            const regex = new RegExp( "^[^<>=:]+$" );
                            if (
                                !newData.destino ||
                                !regex.test( newData.destino )
                            )
                            {
                                reject();
                            }
                            http
                                .put( "destino", newData )
                                .then( () => {
                                    /* const index = data.indexOf( oldData );
                                    data[ index ] = newData;
                                    this.setState( { data }, () =>
                                        resolve()
                                    ); */
                                } )
                                .catch( ( error ) => {
                                    if (
                                        error.response &&
                                        error.response.data
                                    )
                                    {
                                        console.log( error.response.data );
                                        reject();
                                    }
                                } );
                        } ),
                    onRowDelete: ( oldData ) =>
                        new Promise( ( resolve ) => {
                            http
                                .delete( `destino/${ oldData.id }` )
                                .then( () => {
                                    //const index = data.indexOf( oldData );
                                    //data.splice( index, 1 );
                                    /* this.setState( { data }, () =>
                                        resolve()
                                    ); */
                                } );
                        } ),
                } }
            />




        </div>
    );



};

export default Portales;