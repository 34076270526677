import { useHistory } from "react-router-dom";

export default function Login () {

    let history = useHistory();
    localStorage.removeItem( "token" );
    history.push( "/login" );


    return ( <div></div> );

}

