import React from 'react';
import { Button, Card, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import './Mapa.css';
import GoogleMapReact from 'google-map-react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { OverlayView } from '@react-google-maps/api';
import Paper from '@material-ui/core/Paper';

/* 
documentacion 
https://react-google-maps-api-docs.netlify.app/#section-introduction

*/

const AnyReactComponent = ( { text } ) => <div style={ { color: "red" } }><VerifiedUserIcon /> <h1>{ text.vin }</h1></div>;


const onClick = ( e ) => {

    console.info( 'Click: ', e );
};


const useStyles = makeStyles( {
    root: {

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        align: "center"

    },
    pos: {
        marginBottom: 12,
    },
} );
const Mapa = ( props ) => {

    const { info } = props;
    console.log( "Datos", info, info.lat, parseFloat( info.long ) );
    const defaultProps = {
        center: {
            lat: parseFloat( info.lat ),
            lng: parseFloat( info.long )
        },
        zoom: 15
    };

    const classes = useStyles();

    return (
        <Card className="papelmapa">

            <div style={ { height: '40vh', width: '100%' } }>
                <GoogleMapReact
                    bootstrapURLKeys={ { key: process.env.REACT_APP_MAPS } }
                    defaultCenter={ defaultProps.center }
                    defaultZoom={ defaultProps.zoom }

                    onClick={ ( e ) => console.log( "click", e ) }
                    options={ {
                        mapTypeControl: true, streetViewControl: true, styles: [ {
                            featureType: "poi.business",
                            elementType: "labels",
                            stylers: [ {
                                visibility: "off"
                            } ]
                        } ],
                    } }

                >


                    <AnyReactComponent
                        lat={ parseFloat( info.lat ) }
                        lng={ parseFloat( info.long ) }
                        text={ info }




                    />
                </GoogleMapReact>
            </div>

        </Card >
    );
};

export default Mapa;