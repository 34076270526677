import React from 'react';
import { Route, Switch } from "react-router-dom";
import Menu from '../../components/menu/menu';
import Tablero from '../tablero/tablero';
import Ubicacion from "../ubicacion/Ubicacion";
import Portales from "../catalogos/Portales";
import Usuarios from "../catalogos/Usuarios";
import Detalle from "../detalle/detalle";
import './main.css';


const Main = () => {
    return (
        <React.Fragment>

            <Menu />
            <Switch>

                <Route path="/administracion_portales" component={ Portales } />
                <Route path="/administracion_usuarios" component={ Usuarios } />
                <Route path="/ubicacion" component={ Ubicacion } />
                <Route path="/detalle" component={ Detalle } />
                <Route path="/" component={ Tablero } />


            </Switch>
        </React.Fragment>
    );
};

export default Main;