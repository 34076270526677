import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";

import './tablero.css';
import { useHistory } from "react-router-dom";
import HeatMap from '../../components/mapa/HeatMap';
import Mapa from '../../components/mapa/Mapa';
import TableSinReporte from '../../components/tables/tableSinReporte';
import TableConReporte from '../../components/tables/tableConReporte';
import socketIOClient from "socket.io-client";
import { useAlert } from 'react-alert';
//import jwt_decode from "../../servicios/decode_jwt";
import http from '../../servicios/httpService';
const ENDPOINT = process.env.REACT_APP_ENDPOINT;//"http://127.0.0.1:5001";
const socket = socketIOClient( ENDPOINT );

//let audio = new Audio( "/static/siren.mp3" );


/*
El audio no funciona si el usuario no interactua con la pagina primero, hay que meter popup para que al inicio interactue y  un boton para habilitar o deshainilitar la alarma
*/


const Tablero = ( props ) => {
    let history = useHistory();
    const alert = useAlert();
    const [ datosSinReporte, setDatosSinReporte ] = useState( [] );
    const [ datosConReporte, setDatosConReporte ] = useState( [] );
    /*     const token = jwt_decode( localStorage.getItem( "token" ) );
        //console.log( "Token decodificado", token );
        //console.log( "Token", token );
        if ( token === null )
        { history.push( "/login" ); } */
    //console.log( "Entro a Tablero, token", jwt_decode() );
    /* const start = () => {
        try
        {
            audio.play();
        } catch ( error )
        {
            console.log( error );
        }

    };
 */
    function play () {
        const audio = new Audio( 'https://s3.us-east-2.amazonaws.com/repuve.moviltrack.com/siren.mp3' );
        audio.play();
    }



    /*     if ( Date.now() >= token && token.exp * 1000 )
        {
            console.log( "token Expirado" );
            history.push( "/login" );
    
        } */

    useEffect( () => {

        const fetchConReporte = async () => {
            //console.log( "Entro a get datos CON Reporte" );
            try
            {
                const response = await http.get( ENDPOINT + "api/con_reporte?order=DESC", {
                    //withCredentials: true,
                } );
                //console.log( "Respuesta Tablero: ", response.data );
                setDatosConReporte( response.data );
            } catch ( error )
            {
                if ( error.response.status === 401 )
                {
                    //console.log( "Error en catch: ", error.response.status );
                    toast.error( "Su sesión caduco, inicie de nuevo" );
                    history.push( "/login" );
                }

            }


        };
        const fetchSinReporte = async () => {
            //console.log( "Entro a get datos CON Reporte" );
            try
            {
                const response = await http.get( ENDPOINT + "api/sin_reporte?order=DESC", {
                    //withCredentials: true,
                } );
                //console.log( "Respuesta Tablero: ", response.data );
                setDatosSinReporte( response.data );
            } catch ( error )
            {
                if ( error.response.status === 401 )
                {
                    //console.log( "Error en catch: ", error.response.status );
                    toast.error( "Su sesión caduco, inicie de nuevo" );
                    history.push( "/login" );
                }

            }


        };

        socket.off( "conReporte").on( "conReporte", data => {
            console.log( "CON REPORTE", data );
            fetchConReporte();
            if ( data !== null )
            {
                try
                {
                    play();
                } catch ( error )
                {
                    console.log( error );
                }
                const mensaje = `Placa: ${ data.placa }, Tipo: ${ data.tipo }, Marca: ${ data.marca }, Color: ${ data.color }, Modelo: ${ data.modelo } `;
                alert.show( mensaje, {
                    title: "Vehiculo con reporte de Robo:",
                    closeCopy: "Cerrar"
                } );
            }
        } );
        socket.off( "sinReporte").on( "sinReporte", data => {
            console.log( "SIN REPORTE", data );
            fetchSinReporte();
        } );

        fetchConReporte();
        fetchSinReporte();

    }, [] );


    return (

        <div className="cuerpo">
            { datosConReporte ? <HeatMap portales={ datosConReporte } /> : <div></div> }

            { datosConReporte ? <TableConReporte tableName="Con Reporte" data={ datosConReporte } /> : <div></div> }
            { datosSinReporte ? <TableSinReporte tableName="Sin Reporte" data={ datosSinReporte } /> : <div></div> }
        </div>
    );



};

export default Tablero;