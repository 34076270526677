import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import './Ubicacion.css';
//import { datos } from '../../datos';

const StyledTableCell = withStyles( ( theme ) => ( {
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
} ) )( TableCell );

const StyledTableRow = withStyles( ( theme ) => ( {
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
} ) )( TableRow );

function createData ( name, calories, fat, carbs, protein ) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData( 'Playa Magna ', 159, 6.0, 24, 4.0 ),
    createData( 'PlayaCar', 237, 9.0, 37, 4.3 ),
    createData( 'El Tintal', 262, 16.0, 24, 6.0 ),
];

const useStyles = makeStyles( {
    table: {
        minWidth: 100,

    },

} );

const Ubicacion = ( props ) => {
    const [ age, setAge ] = React.useState( '' );
    const classes = useStyles();

    const handleChange = ( event ) => {
        setAge( event.target.value );
        console.log( "Age:", event.target.value );
    };

    return (
        <div className="ubicacion">
            <div className="encabezado">
                <Button disableRipple={ true } className="boton" variant="contained" color="default">
                    { age }

                </Button>

                <FormControl variant="outlined" className="selector">
                    <InputLabel id="demo-simple-select-outlined-label">Seleccione Ubicación</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={ age }
                        onChange={ handleChange }
                        label="Seleccione Ubicación"
                    >
                        <MenuItem value="">
                            <em>Seleccione Ubicación</em>
                        </MenuItem>
                        <MenuItem value={ 10 }>Playa Magna</MenuItem>
                        <MenuItem value={ 20 }>Playa Car</MenuItem>


                    </Select>
                </FormControl>
            </div>
            <TableContainer component={ Paper } >
                <Table className={ classes.table } aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Ubicación</StyledTableCell>
                            <StyledTableCell align="right">Datos</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { rows.map( ( row ) => (
                            <StyledTableRow key={ row.name }>
                                <StyledTableCell component="th" scope="row">
                                    { row.name }
                                </StyledTableCell>
                                <StyledTableCell align="right">{ row.calories }</StyledTableCell>
                            </StyledTableRow>
                        ) ) }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Ubicacion;